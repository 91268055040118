const karir = [
  {
    id: 1,
    title: "Staf Human Resources Capital & General Affairs",
    slug: "staf-human-resources-capital-general-affairs",
    thumbnail: "human-resources-capital.jpg",
    status: "closed",
    qualifications: [
      "Perempuan",
      "Pendidikan minimal S1 Psikologi / Manajemen Sumber Daya Manusia",
      "Pengalaman minimal 1 tahun di bidang Human Resources",
      "Menguasai Microsoft Office",
      "Memiliki kemampuan komunikasi dan keterampilan interpersonal yang baik",
      "Memiliki integritas tinggi",
      "Memiliki minat yang besar dalam pengembangan karir karyawan dan membangun budaya perusahaan yang positif",
      "Mampu menganalisis masalah, mencari solusi, dan mengambil keputusan yang efektif",
      "Adaptif dalam menghadapi perubahan dan mampu bekerja dalam lingkungan yang dinamis",
    ],
    location: "Blitar, Jawa Timur",
    benefit: [
      "Jenjang karir",
      "Lingkungan kerja yang nyaman, kolaboratif, dan mendukung pertumbuhan profesional",
      "BPJS Ketenagakerjaan (Jaminan Kecelakaan Kerja, Jaminan Kematian, Jaminan Hari Tua)",
      "BPJS Kesehatan",
      "Tunjangan Makan, Tunjangan Transportasi dan Tunjangan Komunikasi",
    ],
    dateline: "31 Oktober 2024",
    body: "<p>PT. Karta Daya Reksabumi membuka lowongan kerja sebagai Staf Human Resources Capital & General Affairs. Kami mencari individu yang memiliki kemampuan interpersonal yang kuat dan siap untuk menjadi mitra strategis bagi tim manajemen dalam mencapai tujuan perusahaan. Anda akan berperan penting dalam mengembangkan potensi karyawan dan membangun budaya perusahaan yang positif.</p>",
  },
  {
    id: 2,
    title: "Staf Administrasi Perkantoran",
    slug: "staf-administrasi-perkantoran",
    thumbnail: "office-administration-staff.jpg",
    status: "closed",
    qualifications: [
      "Perempuan",
      "Pendidikan minimal D3 semua jurusan",
      "Pengalaman minimal 1 tahun di bidang Administrasi Perkantoran, khususnya dalam pengelolaan dokumen, surat menyurat, atau dukungan operasional administratif lainnya",
      "Teliti dan memiliki inisiatif yang tinggi",
      "Menguasai Microsoft Office dan aplikasi berbasis IT lainnya",
      "Memiliki pemahaman standar/normatif mengenai sistem manajemen dokumen",
      "Adaptif dalam menghadapi perubahan dan mampu bekerja dalam lingkungan yang dinamis",
    ],
    location: "Blitar, Jawa Timur",
    benefit: [
      "Jenjang karir",
      "Lingkungan kerja yang nyaman, kolaboratif, dan mendukung pertumbuhan profesional",
      "BPJS Ketenagakerjaan (Jaminan Kecelakaan Kerja, Jaminan Kematian, Jaminan Hari Tua)",
      "BPJS Kesehatan",
      "Tunjangan Makan, Tunjangan Transportasi dan Tunjangan Komunikasi",
    ],
    dateline: "31 Oktober 2024",
    body: "<p>PT. Karta Daya Reksabumi membuka kesempatan bagi Anda untuk bergabung sebagai Staf Administrasi yang berorientasi pada detail. Kami mencari individu yang memiliki kemampuan organisasi yang kuat, proaktif, dan siap menjadi mitra strategis bagi tim manajemen. Anda akan bertanggung jawab dalam mendukung kelancaran operasional kantor sehari-hari.</p>",
  },
  {
    id: 3,
    title: "Staf Administrasi Project",
    slug: "staf-administrasi-project",
    thumbnail: "project-administration-staff.jpg",
    status: "closed",
    qualifications: [
      "Laki-laki",
      "Pendidikan minimal D3 semua jurusan",
      "Pengalaman minimal 1 tahun di bidang Administrasi Proyek, khususnya dalam pengelolaan dokumen proyek dan dukungan operasional proyek lainnya",
      "Teliti dan memiliki inisiatif yang tinggi",
      "Menguasai Microsoft Office dan aplikasi berbasis IT lainnya",
      "Memiliki pemahaman administrasi dan operasional proyek",
      "Adaptif dalam menghadapi perubahan dan mampu bekerja dalam lingkungan yang dinamis",
      "Bersedia melakukan perjalanan dinas luar kota",
    ],
    location: "Blitar, Jawa Timur",
    benefit: [
      "Jenjang karir",
      "Lingkungan kerja yang nyaman, kolaboratif, dan mendukung pertumbuhan profesional",
      "BPJS Ketenagakerjaan (Jaminan Kecelakaan Kerja, Jaminan Kematian, Jaminan Hari Tua)",
      "BPJS Kesehatan",
      "Tunjangan Makan, Tunjangan Transportasi dan Tunjangan Komunikasi",
    ],
    dateline: "31 Oktober 2024",
    body: "<p>PT. Karta Daya Reksabumi membuka kesempatan bagi Anda untuk bergabung sebagai Staf Administrasi Proyek yang berorientasi pada detail. Anda akan berperan penting dalam memastikan kelancaran operasional proyek dan memberikan dukungan administratif kepada tim proyek.</p>",
  },
];

export default karir;
