const users = [
  {
    id: 1,
    name: "Ibnu Ahmad Fauzi",
    username: "iibnufauzii",
    password: "U2FsdGVkX19fWs2pSlyM1ShpRjFCuDqwJMBKkHFdyEg=",
  },
  {
    id: 2,
    name: "Administrator",
    username: "administrator",
    password: "U2FsdGVkX19fWs2pSlyM1ShpRjFCuDqwJMBKkHFdyEg=",
  },
];

export default users;
